<template>
  <!-- 个人中心 -->
  <div>
    <div class="wPersonal-page">
      <div class="wPer-title">个人中心</div>
      <el-form
        ref="wPer_form"
        status-icon
        :rules="wPer_rules"
        :model="wPersonal"
        label-width="100px"
      >
        <el-form-item label="账号：" prop="account">
          <div class="wShowText">{{ wPersonal.account }}</div>
        </el-form-item>
        <el-form-item label="密码：" prop="password">
          <div class="wShowText">*********</div>
          <el-button
            type="text"
            style="margin-left: 10px"
            @click="wToEditPWD()"
          >
            更改
          </el-button>
        </el-form-item>
        <el-form-item label="昵称：" prop="nickname">
          <el-input
            v-model="wPersonal.nickname"
            placeholder="用户昵称"
            style="width: 100%; max-width: 250px"
          ></el-input>
        </el-form-item>
        <el-form-item label="角色：" prop="role">
          <div class="wShowText">{{ wPersonal.role }}</div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('wPer_form')">
            修改
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import { other_queryUserInfo, other_updateUserInfo } from "@/network/wApi.js";

export default {
  data() {
    return {
      // 个人表单信息
      wPersonal: {
        account: "", // 账号
        nickname: "", // 昵称
        role: "", // 角色
      },
      // 个人表单验证规则
      wPer_rules: {
        nickname: [
          { required: true, message: "请输入您的昵称!", trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (value.trim()) {
                this.wPersonal.nickname = value.trim();
                callback();
              } else {
                this.wPersonal.nickname = value.trim();
                callback(new Error("请输入您的昵称!"));
              }
            },
            trigger: "blur",
          },
        ],
      },

      // 提交保存修改密码
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            // 请求参数
            let wData = {};

            // 获取值
            wData.name = this.wPersonal.nickname;

            // 请求函数
            other_updateUserInfo(wData).then((res) => {
              this.$message.success("接口待对接!");
              // 获取用户信息
              this.wQueryUserInfo();
            });
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
    };
  },
  mounted() {
    // 获取用户信息
    this.wQueryUserInfo();
  },
  methods: {
    /**
     * wQueryUserInfo
     * 获取用户信息
     */
    wQueryUserInfo() {
      // 请求函数
      other_queryUserInfo().then((res) => {
        // 更新数据
        this.wPersonal.account = res.account;
        this.wPersonal.nickname = res.name;
        this.wPersonal.role = res.roleName;
      });
    },

    /**
     * wToEditPWD
     * 跳转至修改密码
     */
    wToEditPWD() {
      // 跳至修改密码
      this.$router.push({ name: "password" });
    },
  },
};
</script>

<style lang="less" scoped>
@import "@/assets/css/publicStyle.less";

// 个人中心
.wPersonal-page {
  display: block;
  width: 100%;
  padding: 20px 20px;
  box-sizing: border-box;
  background-color: #ffffff;
  user-select: none;

  .wPer-title {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: bolder;
    color: #333333;
    margin-bottom: 15px;
  }

  .wShowText {
    display: inline;
    font-size: 16px;
    color: #333333;
  }
}
</style>